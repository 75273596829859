import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ChangeDetectionStrategy, Component, HostBinding, ViewChild, effect, inject, input, output } from '@angular/core';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumPlus, IconBigMediumSchliessen, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-accordion-item',
    templateUrl: './accordion-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CdkAccordionItem, IconComponent, TranslocoDirective],
    providers: [provideTranslationScope('uiAccordion', /* istanbul ignore next */ async (lang: string, root: string) => import(`../../${root}/${lang}.json`))],
})
export class AccordionItemComponent {
    private readonly scrollService = inject(ScrollService);

    public readonly iconPlus = IconBigMediumPlus;
    public readonly iconClose = IconBigMediumSchliessen;

    public readonly index = input.required<number>();
    public readonly title = input.required<string>();
    public readonly isOpen = input<boolean>(false);

    public readonly opening = output<number>();

    // TODO: 🔥 why this breaks the unit tests?
    // Issue: https://github.com/help-me-mom/ng-mocks/issues/8634
    // public readonly accordionItem = viewChild(CdkAccordionItem);
    @ViewChild('accordionItem') public readonly accordionItem?: CdkAccordionItem;
    @HostBinding('class') public readonly classList = 'block mb-4 last:mb-0';

    public constructor() {
        effect(() => {
            if (this.isOpen()) {
                this.accordionItem?.open();
            } else {
                this.accordionItem?.close();
            }
        });
    }

    public itemClicked(accordionItem: CdkAccordionItem): void {
        accordionItem.toggle();

        if (accordionItem.expanded) {
            this.opening.emit(this.index());

            // this.onClicked.emit(accordionItem.i)
            const scrollSelector = `#${accordionItem.id}`;
            this.scrollService.scroll(scrollSelector, { scrollIntoView: true, scrollIntoViewOptions: { block: 'end' } });
        }
    }
}
